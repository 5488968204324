<template>
    <div>
        <div class="section mt-2 text-center">
            <img class="auth-logo" src="../../assets/img/metriclogo_color.png" alt="metrics logo" style="">
            <h3 class="mt-3">Update Password</h3>
            <!--            <h4>Fill the form to log in</h4>-->
        </div>
        <div class="section mb-5 p-2">
            <div class="splash-page mt-5 mb-5">
                <template v-if="loading">
                    <div class="iconbox mb-3" style="background-color: transparent">
                        <div class="spinner-grow text-primary" role="status"></div>
                    </div>
                    <h2 class="mb-2">Loading...</h2>
                    <p>
                        <i>Please wait</i>
                    </p>
                </template>
                <template v-else-if="error">
                    <div class="iconbox mb-3" style="background-color: transparent">
                        <ion-icon name="warning-outline" class="text-warning"></ion-icon>
                    </div>
                    <h2 class="mb-2">Oops...</h2>
                    <p>
                        {{message}}
                    </p>
                </template>
                <template v-else>
                    <form role="form" @submit.prevent="updatePass">
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="pass">Password</label>
                                        <input type="password" v-model="password" class="form-control" id="pass" placeholder="********">
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="pass2">Re-enter Password</label>
                                        <input type="password" v-model="repass" class="form-control" id="pass2" placeholder="********">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-links mt-2">
                            <!--                    <div>-->
                            <!--                        <router-link :to="{name: 'Register'}">Register Now</router-link>-->
                            <!--                    </div>-->
                        </div>

                        <div class="form-button-group transparent">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Update Password</button>
                        </div>

                    </form>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'confirmResetPassword',
    data(){
        return {
            loading: true,
            error: false,
            password: '',
            repass: '',
            user_id: ''
        }
    },
    methods: {
        async updatePass(){
            if(!(this.password === this.repass)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Passwords do not match!',
                    timeout: 1500
                })
            }
            if(this.password.length <= 7){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Your password should be greater than 8 characters!',
                    timeout: 2000
                })
            }
            const {password} = this;
            this.$loader.show();
            let response = await this.$store.dispatch('auth/updatePassword', {
                email: this.$route.params.email,
                password
            });
            this.$loader.hide();
            if(response.status){
                await this.$store.dispatch('user/updateUserProfile', {
                    userId: this.user_id, userData: {password_reset_ref: ''}
                })
                await this.$store.dispatch('notification/display_noti', {
                    message: 'Password updated!',
                    timeout: 1500
                });
                return this.$router.push({name: 'Auth'})
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                })
            }
        },
        async testLink(){
            const {email, passRef} = this.$route.params;
            let response = await this.$store.dispatch('user/getUserByEmail', email);
            if(response.status){
                response = await this.$store.dispatch('user/getUserProfile', response.data.id);
                if(response.status){
                    if(passRef === response.data.data.password_reset_ref){
                        this.user_id = response.data.id;
                    }else{
                        this.error = true;
                        this.message = "An unknown error occurred"
                    }
                }else{
                    this.error = true;
                    this.message = "Invalid Token"
                }
            }else{
                this.error = true;
                this.message = "Invalid Token"
            }
            this.loading = false;
        }
    },
    mounted() {
        this.testLink()
    }
};
</script>

<style scoped>

</style>